import Link from 'next/link';
import classNames from 'classnames';
import { Container, Row, Col } from 'reactstrap';
import { Section } from '../Sections';

interface Props {
  pageTheme: {
    color: string;
    mode: string;
    invertColors: boolean;
  };
}

export const Footer: React.FunctionComponent<Props> = ({ pageTheme }) => {
  const footerClass = classNames('footer', {
    ['footer--dark-theme']: pageTheme.mode === 'dark',
    ['footer--inverted']: pageTheme.invertColors,
  });

  return (
    <Section hasFooter hasBottom={false} backgroundColor={pageTheme.color}>
      <Container fluid>
        <footer style={{ color: pageTheme.color }} className={footerClass}>
          <Row>
            <Col md={4}>
              <ul>
                <li>
                  <Link href="/credits">
                    <a>Credits</a>
                  </Link>
                </li>

                <li>
                  <Link href="/legal">
                    <a>Legal & Privacy Policy</a>
                  </Link>
                </li>
                <li>
                  <Link href="/accessibility">
                    <a>Accessibility</a>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <p>
                {/* ASTORIA WEST <br />
                  Leasing Office <br />
                  30-77 Vernon Blvd, Astoria Queens, NY 11102
                  <br /><br /> */}
                ASTORIA WEST <br />
                11-12 30th Drive, Astoria Queens, NY 11102
                <br />
                <br />© {new Date().getFullYear()} ASTORIA WEST <br />
                Astoria Queens Apartments for Rent
              </p>
            </Col>
            <Col md={4}>
              <p>
                Astoria West and Exclusive Leasing and Marketing Agent fully
                support the principles of the&nbsp;
                <Link href="https://linktr.ee/corcorangroup">
                  <a target="_blank" rel="noopener">
                    Standard Operating Procedures. Fair Housing Act and the
                    Equal Opportunity Act.
                  </a>
                </Link>
              </p>
            </Col>
          </Row>
        </footer>
      </Container>
    </Section>
  );
};
