import { FC } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { Button } from '../Buttons';

interface MainNavigationProps {
  theme?: string;
  isMobile?: boolean;
  activeNavItem?: string;
  pageColor?: string;
}

export const MainNavigation: FC<MainNavigationProps> = ({
  theme,
  activeNavItem,
  pageColor,
}) => {
  const residencesClass = classNames('nav--main__item', {
    ['nav--main__item--current']: activeNavItem === '/residences',
  });

  const neighborhoodClass = classNames('nav--main__item', {
    ['nav--main__item--current']: activeNavItem === '/neighborhood',
  });

  const amenitiesClass = classNames('nav--main__item', {
    ['nav--main__item--current']: activeNavItem === '/amenities',
  });

  const availabilityClass = classNames(
    'nav--main__item nav--main__item--includes-button',
    {
      ['nav--main__item--includes-button--current']:
        activeNavItem === '/availability',
    }
  );
  1;
  const contactClass = classNames(
    'nav--main__item nav--main__item--includes-button',
    {
      ['nav--main__item--includes-button--current']:
        activeNavItem === '/contact',
    }
  );

  return (
    <div className="nav--main">
      <ul>
        <li className={residencesClass}>
          <Link href="/residences">
            <a>Residences</a>
          </Link>
        </li>

        <li className={neighborhoodClass}>
          <Link href="/neighborhood">
            <a>Neighborhood</a>
          </Link>
        </li>
        <li className={amenitiesClass}>
          <Link href="/amenities">
            <a>Amenities</a>
          </Link>
        </li>
        <li className={availabilityClass}>
          <Button
            title="FAQ"
            href="https://garnet-vein-480.notion.site/Astoria-West-Lottery-Phase-2-FAQs-fb1d74b37c3346cbaa9242fd44d26f8a"
            targetExternal
            outline={theme === 'dark' ? 'dark' : ''}
          />
        </li>
        <li className={contactClass}>
          <Button
            title="Contact"
            href="mailto:info@astorialottery.com"
            outline={theme === 'dark' ? 'dark' : ''}
          />
        </li>
      </ul>
    </div>
  );
};
