import React, { FC } from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import { SocialMediaIcons } from './social-media-icons';

interface Props {
  show: boolean;
  activeNavItem?: string;
  setShowNavigation: Function;
}

export const SubNavigation: FC<Props> = ({
  show,
  setShowNavigation,
  activeNavItem,
}) => {
  const subNavClass = classNames('nav--sub', {
    ['nav--sub--active']: show,
  });

  const viewClass = classNames('nav--sub__item', {
    ['nav--sub__item--current']: activeNavItem === '/views',
  });

  const aboutTheArtClass = classNames('nav--sub__item', {
    ['nav--sub__item--current']: activeNavItem === '/about-the-art',
  });

  const pressClass = classNames('nav--sub__item', {
    ['nav--sub__item--current']: activeNavItem === '/press',
  });

  const flexLivingClass = classNames('nav--sub__item', {
    ['nav--sub__item--current']: activeNavItem === '/flex-living',
  });

  return (
    <div
      onFocus={() => setShowNavigation(true)}
      onBlur={() => setShowNavigation(false)}
      className={subNavClass}
    >
      <ul>
        <li className={viewClass}>
          <Link href="/views">
            <a>Views</a>
          </Link>
        </li>

        <li className={flexLivingClass}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://prismic-io.s3.amazonaws.com/astoria-west/95371922-fef9-477f-93f1-ce7fdffbbde9__22.11.11_AW.Public.Transport.Maps.Flyer.FINAL.pdf"
          >
            Getting around
          </a>
        </li>

        <li className={aboutTheArtClass}>
          <Link href="/about-the-art">
            <a>About the art</a>
          </Link>
        </li>
      </ul>
    </div>
  );
};
